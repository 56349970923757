import axios from 'axios';

if (process.env.VUE_APP_BASE_API) {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
}

axios.interceptors.request.use(
  (config) => {

    if (!config.headers) {
      config.headers = {};
    }

    return config;
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);
// add response interceptors
axios.interceptors.response.use(
  (response) => {

    if ((response.headers['content-disposition'] || ! /^application\/json/.test(response.headers['content-type'])) && response.status === 200) {
      return response
    }
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 1) {
      // Message.error({
      //   content: res.msg || 'Error',
      //   duration: 10 * 1000,
      // });
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        [50008, 50012, 50014].includes(res.code) &&
        response.config.url !== '/api/user/info'
      ) {
        this.$confrim(
          'You have been logged out, you can cancel to stay on this page, or log in again',
          'Confirm logout',
          {
            confirmButtonText: 'Re-Login',
            cancelButtonText: 'Cancel',
            type: 'error',
          }
        ).then(() => {
          window.location.reload();
        })
      }

      // // @ts-ignore
      // if(res.message.includes('鉴权异常') || res.message.includes('未登录')){
      //   router.replace({name:'login'})
      // }

      // @ts-ignore
      return Promise.reject(res);
    }
    return res;
  },
  (error) => {
    // Message.error({
    //   content: error.message || 'Request Error',
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  }
);
